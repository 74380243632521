/* eslint-disable */
import queryString from 'query-string'

// api config
import { API_CONFIG_TIMEOUT } from '../../api/config'

// utilities
import responseHandler from './responseHandler'
import { getLocalStorage } from '../localStorage'

// get fetch utility
export default (url = '', opts = null) => {
  const controller = new AbortController()
  const signal = controller.signal

  // const headers = {
  //   'Content-Type': 'application/json',
  // }

  const token = getLocalStorage("token")
  let headers = {"Content-Type": "application/json"};
  if (token) {
    headers["Authorization"] = `Token ${token}`;
  }

  if (opts) {
    url = `${url}?${queryString.stringify(opts)}`
  }

  const request = fetch(url, {
    signal,
    headers,
    method: 'GET',
    mode: 'cors',
  })

  setTimeout(() => controller.abort(), API_CONFIG_TIMEOUT)

  return responseHandler(request)
}